<template>
  <div id="statistik-user" data-app>

    <div class="gutter-b">
        <b-button v-b-toggle.collapse-filter variant="white" size="lg" class="bg-white font-weight-bolder w-100">Filtering Data</b-button>
        <b-collapse :visible="visible" id="collapse-filter">
            <ValidationObserver ref="formAdd">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <div class="card card-custom card-stretch">
                    <div class="card-body d-flex p-0 bg-white">
                        <div class="flex-grow-1 p-8 card-rounded bgi-no-repeat d-flex align-items-center">  
                            <div class="row w-100">
                                <div class="col-12 col-sm-6">
                                    <validationProvider name="Display" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group
                                    id="input-group-1"
                                    label="Display:"
                                    label-for="input-1"
                                    :state="valid"
                                    :invalid-feedback="errors[0]"
                                    class="m-0"
                                    >
                                        <b-form-select2
                                        id="input-1"
                                        v-model="filter.display"
                                        variant="solid"
                                        size="lg"
                                        :options="opsDisplay"
                                        placeholder="Silahkan pilih Display"
                                        :state="valid"
                                        ></b-form-select2>
                                    </b-form-group>
                                    </validationProvider>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <validationProvider name="Kategori" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group
                                    id="input-group-2"
                                    label="Kategori:"
                                    label-for="input-2"
                                    :state="valid"
                                    :invalid-feedback="errors[0]"
                                    class="m-0"
                                    >
                                        <b-form-select2
                                        id="input-2"
                                        v-model="filter.kategori"
                                        variant="solid"
                                        size="lg"
                                        :options="opsKategori"
                                        placeholder="Silahkan pilih Kategori"
                                        :state="valid"
                                        ></b-form-select2>
                                    </b-form-group>
                                    </validationProvider>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <validationProvider name="Perusahaan" v-slot="{ valid, errors }">
                                    <b-form-group
                                    id="input-group-3"
                                    label="Perusahaan:"
                                    label-for="input-3"
                                    class="m-0"
                                    :state="valid"
                                    :invalid-feedback="errors[0]"
                                    >
                                        <b-form-select2
                                        id="input-3"
                                        v-model="filter.perusahaan"
                                        variant="solid"
                                        size="lg"
                                        :options="opsPerusahaan"
                                        placeholder="Silahkan pilih Perusahaan"
                                        :state="valid"
                                        ></b-form-select2>
                                    </b-form-group>
                                    </validationProvider>
                                </div>                
                                <div class="col-12 col-sm-6">
                                    <b-form-group
                                    id="input-group-4"
                                    label="Periode:"
                                    label-for="input-4"
                                    class="m-0"
                                    description="default tahun berjalan"
                                    >
                                        <b-button 
                                        type="button"
                                        id="input-4" 
                                        class="form-control form-control-solid text-left" 
                                        variant="outline-secondary" 
                                        size="lg"
                                        @click="modalDate = true">{{ showDate }}
                                        </b-button>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button  
                        type="submit"
                        ref="kt_submit"
                        class="btn btn-primary font-weight-bold"
                        >
                        Tampilkan
                        </button>
                    </div>
                </div>
            </b-form>
            </ValidationObserver>
        </b-collapse>
    </div>

    <component v-if="curComponent" :sdata="sdataForm" v-bind:is="curComponent"></component>

    <v-dialog
        ref="dialogDate"
        v-model="modalDate"
        persistent
        width="290px"
    >
        <v-date-picker
        v-model="dates"
        scrollable
        range
        color="bg-primary"
        header-color="bg-primary"
        >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="modalDate = false"
        >
            Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="setDate()"
        >
            OK
        </v-btn>
        </v-date-picker>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import ProfileService from "@/core/services/api/profile.service";
import ErrorService from "@/core/services/error.service";

import ChartTemplate from "@/view/pages/statistik/display/Chart.vue"
import TableTemplate from "@/view/pages/statistik/display/Table.vue"

export default {
  name: "statistik-user",
  components: {
      ChartTemplate,
      TableTemplate
  },
  data() {
    return {
        show: true,
        modalDate: false,
        isBusy: true,
        dates: [],
        visible: true,
        filter: {
            display: null,
            tgl_awal: null,
            tgl_akhir: null,
            kategori: null,
            perusahaan: null,
        },
        curComponent: "ChartTemplate",
        sdataForm: null,
        opsDisplay: [
            { value: null, text: "Silahkan pilih Display" },
            { value: 'table', text: "Table" },
            { value: 'chart', text: "Chart" },
        ],
        opsKategori: [
            { value: null, text: "Silahkan pilih Kategori" },
            { value: 'nasional', text: "Populasi, Produksi dan Distribusi" },
            { value: 'project', text: "Data Project" }
        ],
        opsPerusahaan: [
            { value: null, text: "Semua Perusahaan" }
        ],
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    showDate() {
        return (this.filter.tgl_awal || this.filter.tgl_akhir ? `${this.filter.tgl_awal} ~ ${this.filter.tgl_akhir}` : 'Silahkan klik untuk pilih tanggal')
    }
  },
  methods: {      
    setDate() {
        if (this.dates.length < 1) {
            ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan pilih tanggal terlebih dahulu',
            })
            return;
        }
        if (this.dates.length < 2) {
            this.dates.push(this.dates[0])
        }
        this.dates.sort()
        this.filter.tgl_awal = this.dates[0]
        this.filter.tgl_akhir = this.dates[1]
        this.modalDate = false
        this.errorDate = false
    },
    getPerusahaan() {
        ProfileService.getCompanyAkses({ child: 2 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsPerusahaan = [{
                    value: null,
                    text: "Semua Perusahaan"
                }]
                return;
            }

            this.opsPerusahaan = res.data.data
            this.opsPerusahaan.push({
                value: null,
                text: "Semua Perusahaan"
            })
        })
        .catch((err) => {
            this.opsPerusahaan = [{
                value: null,
                text: "Tidak ada Perusahaan"
            }]
            ErrorService.status(err)
        })
    },removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }
        
        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
            this.removeSpinner(submitButton)
            this.visible = true
            this.$nextTick(() => {
                this.visible = false
            })

            window.scrollTo({
                top: 100,
                left: 0,
                behavior: 'smooth'
            });
        }, 500);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
        this.filter.display= null
        this.filter.kategori= null
        this.filter.tgl_awal= null
        this.filter.tgl_awal= null
        this.filter.perusahaan= null

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Statistik" }]);
    if (!this.currentUser.menu.statistik) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getPerusahaan()
  },
};
</script>